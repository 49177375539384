import { lazy } from "react";
import ScheduleDetails from "../../views/pages/Show/ScheduleDetails";
import SelfAssessmentDetails from "../../views/pages/Show/SelfAssessment";
const UserDetails = lazy(() => import("../../views/pages/Show/UserDetails"));

const RegionDetails = lazy(() =>
  import("../../views/pages/Show/RegionDetails")
);
const SiteDetails = lazy(() => import("../../views/pages/Show/SiteDetails"));
const RiskAndComplianceDetails = lazy(() =>
  import("../../views/pages/Show/RiskAndComplianceDetails")
);

const CorrectionPlanDetails = lazy(() =>
  import("../../views/pages/Show/CorrectionPlanDetails")
);

const ModuleDetails = lazy(() =>
  import("../../views/pages/Show/ModuleDetails")
);
const MedicationDetails = lazy(() =>
  import("../../views/pages/Show/MedicationDetails")
);

// %templateImport%

const MedicineAvailability = lazy(() =>
  import("../../views/pages/Show/MedicineAvailability")
);

const DetailsRouter = [
  {
    element: <UserDetails />,
    path: "/Users/Details/:id",
    route: "Users",
    slug: "Users",
    title: "Users",
  },
  {
    element: <RiskAndComplianceDetails />,
    path: "/Standards/Details/:id",
    route: "risk-compliance",
    slug: "Standards",
    title: "Risk & Compliance",
  },

  {
    element: <CorrectionPlanDetails />,
    path: "/Correction-plan/Details/:id",
    route: "Correction-plan",
    slug: "Correction-plan",
    title: "Correction Plan",

    onlyFor: [
      "admin",
      "regional representatives",
      "ticketing_representative",
      "cluster manager",

      "supervisor",
      "facility",
      "facility manager",
      "regulator representative",
      "hold_co",
      "compliance_legal_admin"
    ],
  },

  {
    element: <RegionDetails />,
    path: "/Regions/Details/:id",
    route: "Regions",
    slug: "Regions",
    title: "Regions",
  },
  {
    element: <SiteDetails />,
    path: "/Sites/Details/:id",
    route: "Sites",
    slug: "Sites",
    title: "Sites",
  },
  {
    element: <ScheduleDetails />,
    path: "/Schedule-Task/Details/:id",
    route: "Schedule-Task",
    slug: "Schedule-Task",
    title: "Schedule-Task",
  },
  {
    element: <ModuleDetails />,
    path: "/Modules/Details/:id",
    route: "Modules",
    slug: "Modules",
    title: "Modules",
  },
  {
    element: <MedicationDetails />,
    path: "/Medication-Details/:id",
    route: "Pharmacy Medication Details",
    slug: "Pharmacy Medication Details",
    title: "Pharmacy Medication Details",
  },
  {
    element: <SelfAssessmentDetails />,
    path: "/Self-Assessment/Details/:id",
    route: "Self-Assessment",
    slug: "Self-Assessment",
    title: "Self Assessment - Medicine Availability Form",
  },

  {
    element: <SelfAssessmentDetails key={"tasks-route"} />,
    path: "/Self-Assessment/Task/:task_id",
    route: "Self-Assessment",
    slug: "Self-Assessment",
    title: "Self Assessment - Medicine Availability Form",
  },
  {
    element: <MedicineAvailability />,
    path: "/Medicine-Availability/Details/:id",
    route: "Medicine-Availability",
    slug: "Medicine-Availability",
    title: "Medicine Availability Form",
  },
  {
    element: <MedicineAvailability key={"tasks-route"} />,
    path: "/Medicine-Availability/Task/:task_id",
    route: "Medicine-Availability",
    slug: "Medicine-Availability",
    title: "Medicine Availability Form",
  },
  // %template%
];
export default DetailsRouter;
