import { lazy } from "react";
import ComplianceDashboard from "../../views/pages/Graph/ComplianceDashboard";
import FormList from "../../views/DynamicForm/list/FormList";
import ScheduleAvailability from "../../views/pages/List/ScheduleAvailability";
import ScheduleMedical from "../../views/pages/List/ScheduleMedical";
import { element } from "prop-types";
import NotificationList from "../../views/pages/List/Notification";

const SubmissionList = lazy(() =>
  import("../../views/DynamicForm/list/SubmissionList")
);

const SelfAssessment = lazy(() =>
  import("../../views/pages/List/SelfAssessment")
);

//const Pagination = lazy(() => import("../../views/pages/List/Pagination"));
const UserList = lazy(() => import("../../views/pages/List/UserList"));
const Calander = lazy(() => import("../../views/pages/List/calendar"));
const MedicalErrorCalendar = lazy(() =>
  import("../../views/pages/List/calendarMedicalError")
);

const RegionList = lazy(() => import("../../views/pages/List/RegionList"));
const RiskComplianceList = lazy(() =>
  import("../../views/pages/List/RiskComplianceList")
);

const ModuleList = lazy(() => import("../../views/pages/List/ModuleList"));
const SiteList = lazy(() => import("../../views/pages/List/SiteList"));
const ClusterList = lazy(() => import("../../views/pages/List/ClusterList"));
const ScheduleTask = lazy(() => import("../../views/pages/List/ScheduleTask"));
const VideoSessions = lazy(() =>
  import("../../views/pages/List/VideoSessions")
);
const ScheduleSelfAssessment = lazy(() =>
  import("../../views/pages/List/ScheduleSelfAssessment")
);
const CorrectionPlan = lazy(() =>
  import("../../views/pages/List/CorrectionPlan")
);

const SelfAssessmentTasks = lazy(() =>
  import("../../views/pages/List/ScheduleSelfAssessmentTasks")
);
const ScheduleMedicineAvailability = lazy(() =>
  import("../../views/pages/List/ScheduleMedicineAvailability")
);
const MedicineAvailabilityTasks = lazy(() =>
  import("../../views/pages/List/ScheduleMedicineAvailabilityTasks")
);

const MyTasks = lazy(() => import("../../views/pages/List/MyTasks"));
const MyMedicalErrorTask = lazy(() =>
  import("../../views/pages/List/MyMedicalErrorTask")
);

const ObligationGraph = lazy(() => import("../../views/pages/Graph/pharmacy"));
// %templateImport%

const MedicineAvailability = lazy(() =>
  import("../../views/pages/List/MedicineAvailability")
);

const ListRoutes = [
  {
    element: <UserList />,
    path: "/Users",
    route: "Users",
    slug: "Users",
    title: "Users",
  },
  {
    element: <RegionList />,
    path: "/Regions",
    route: "Regions",
    slug: "Regions",
    title: "Regions",
  },

  {
    element: <RiskComplianceList />,
    path: "/Standards",
    route: "risk-compliance",
    slug: "Standards",
    title: "Risk & Compliance",
  },

  {
    element: <ModuleList />,
    path: "/Modules",
    route: "Modules",
    slug: "Modules",
    title: "Modules",
  },
  {
    element: <SiteList />,
    path: "/Sites",
    route: "Sites",
    slug: "Sites",
    title: "Sites",
  },
  {
    element: <ClusterList />,
    path: "/Clusters",
    route: "Clusters",
    slug: "Clusters",
    title: "Clusters",
  },
  {
    element: <NotificationList/>,
    path: "/Notifications",
    route: "Notifications",
    slug: "Notifications",
    title: "Notifications",
  },

  ///dashboards
  {
    element: <ComplianceDashboard />,
    path: "/Compliance-Dashboard",
    title: "Compliance Dashboard",
    route: "Compliance Dashboard",
    slug: "Compliance-Dashboard",
    onlyFor: [
      "admin",
      "supervisor",
      "cluster manager",
      "regional representatives",
      "hold_co",
    ],
  },
  {
    element: <ObligationGraph />,
    path: "/General-Hospital/Dashboard",
    route: "General-Hospital",
    slug: "General-Hospital",
    title: "General Hospital Form",
  },
  {
    element: <ObligationGraph />,
    path: "/Self-Assessment/Dashboard",
    route: "Self-Assessment",
    slug: "Self-Assessment",
    title: "Self Assessment - Medicine Availability Form",
  },
  {
    element: <ObligationGraph />,
    path: "Regular-Visit/Dashboard",
    route: "Regular Visit",
    slug: "Regular-Visit",
    title: "Regular Visit Form",
  },
  {
    element: <ObligationGraph />,
    path: "/Specialized-Medical/Dashboard",
    route: "Specialized-Medical",
    slug: "Specialized-Medical",
    title: "Specialized Medical Form",
  },

  {
    element: <ObligationGraph />,
    path: "/General-Medical-Complex/Dashboard",
    route: "General-Medical-Complex",
    slug: "General-Medical-Complex",
    title: "General Medical Complex Form",
  },

  {
    element: <SelfAssessment />,
    path: "/Self-Assessment",
    route: "Self-Assessment",
    slug: "Self-Assessment",
    title: "Self Assessment- Medicine Availability Form",
  },
  {
    element: <ScheduleTask />,
    path: "/Schedule-Task",
    route: "Schedule Task",
    slug: "Schedule Task",
    title: "Schedule Task Form",
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },
  {
    element: <Calander />,
    path: "/Schedule-Task-Availability/Schedule-Calander",
    route: "Schedule Calander",
    slug: "Schedule Calander",
    title: "Schedule Calander Form",
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },
  {
    element: <ScheduleAvailability />,
    path: "/Schedule-Task-Availability",
    route: "Schedule Availability",
    slug: "Schedule Availability",
    title: "Schedule Availability Form",
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },

  {
    element: <MedicalErrorCalendar />,
    path: "/Schedule-Medical-Error/Schedule-Calander",
    route: "Schedule Calander",
    slug: "Schedule Calander",
    title: "Schedule Calander Form",
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },
  {
    element: <ScheduleMedical />,
    path: "/Schedule-Medical-Error",
    route: "Schedule Medical Error",
    slug: "Schedule Medical Error",
    title: "Schedule Medical Error Form",
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },
  {
    element: <VideoSessions />,
    path: "/Virtual-Sessions",
    route: "Video Sessions",
    slug: "Virtual-Sessions",
    title: "Virtual Sessions",
    onlyFor: [
      "admin",
      "regional representatives",
      "agent",
      "supervisor",
      "expert",
    ],
  },
  {
    element: <ScheduleSelfAssessment />,
    path: "/Schedule-Self-Assessment",
    route: "Schedule Self Assessment",
    slug: "Schedule-Self-Assessment",
    title: "Schedule Self Assessment",
    onlyFor: [
      "admin",
      "regional representatives",
      "facility",
      "facility manager",
      "regulator representative",
      "cluster manager",
      "hold_co",
    ],
  },
  {
    element: <CorrectionPlan />,
    path: "/Correction-plan",
    route: "Correction Plan",
    slug: "Correction-plan",
    title: "Correction Plan",
    onlyFor: [
      "admin",
      "ticketing_representative",
      "regional representatives",
      "facility",
      "supervisor",
      "facility manager",
      "cluster manager",
      "regulator representative",
      "hold_co",
      "compliance_legal_admin"
    ],
  },

  {
    element: <ScheduleMedicineAvailability />,
    path: "/Schedule-Medicine-Availability",
    route: "Schedule Medicine Availability",
    slug: "Schedule-Medicine-Availability",
    title: "Schedule Medicine Availability",
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },
  {
    element: <MyTasks />,
    path: "/My-Task",
    route: "My Task",
    slug: "My Task",
    title: "My Tasks",
    onlyFor: ["agent", "admin", "expert"],
  },
  {
    element: <MyMedicalErrorTask />,
    path: "/My-Medical-Error-Tasks",
    route: "My Medical Error Tasks",
    slug: "My Medical Error Tasks",
    title: "My Medical Error Tasks",
    onlyFor: ["agent", "admin", "expert"],
  },
  {
    element: <SelfAssessmentTasks />,
    path: "/Self-Assessment-Tasks",
    route: "Self Assessment Tasks",
    slug: "Self Assessment Tasks",
    title: "Self Assessment Tasks",
    onlyFor: [
      "cluster manager",
      "facility",
      "facility manager",
      "regulator representative",
    ],
  },
  {
    element: <MedicineAvailabilityTasks />,
    path: "/Medicine-availability-Tasks",
    route: "Medicine Availability Tasks",
    slug: "Medicine Availability Tasks",
    title: "Medicine Availability Tasks",
    onlyFor: ["agent"],
  },
  {
    element: <MedicineAvailability />,
    path: "/Medicine-Availability",
    route: "Medicine-Availability",
    slug: "Medicine-Availability",
    title: "Medicine Availability Form",
  },
  {
    element: <ObligationGraph />,
    path: "/Medicine-Availability/Dashboard",
    route: "Medicine-Availability",
    slug: "Medicine-Availability",
    title: "Medicine Availability Form",
  },
  {
    element: <FormList />,
    path: "/Inspection-Engine",
    route: "Inspection-Engine",
    slug: "Inspection-Engine",
    title: "Inspection Engine",
  },
  {
    element: <SubmissionList />,
    path: "/:id/Form-Builder",
    route: "Form-Builder",
    slug: "Form-Builder",
    title: "Form Builder Form",
  },
  {
    element: <ObligationGraph />,
    path: "/Form-Builder/Dashboard",
    route: "Form-Builder",
    slug: "Form-Builder",
    title: "Form Builder Form",
  },

  // %template%
];
export default ListRoutes;
